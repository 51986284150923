<template>
  <div style="overflow-x: hidden;">
    <div class="index-bg">
      <img class="logo" src="../assets/images/icon/logo.png" alt="">
      <div class="btns">
        <a href="https://play.google.com/store/apps/details?id=com.strategy.north.tower" target="_blank">
          <img src="../assets/images/icon/adr.png" alt="">
        </a>
        <a href="https://apps.apple.com/au/app/north-tower-merge-td-defense/id1626431061 " target="_blank">
          <img src="../assets/images/icon/ios.png" alt="">
        </a>
      </div>
    </div>
    <div class="index-bottom-bg">
      <div class="mask" ></div>
      <div class="box" style="z-index： 99">
        <div :class="isMobile ? 'title-box' : 'title-box pc-width'">
        <img class="left-ls" src="../assets/images/icon/ls.png" alt="">
        <span class="title-text">
          {{$t("index.gamefeatures")}}
        </span>
        <img class="right-ls" src="../assets/images/icon/ls.png" alt="">
      </div>
      <van-swipe :class="isMobile ? 'my-swipe' : 'my-swipe pc-width'" :autoplay="3000" indicator-color="white">
        <van-swipe-item class="my-swipe-item">
          <img class="swiper-img" src="../assets/images/swiper/1.png" alt="">
        </van-swipe-item>
        <van-swipe-item class="my-swipe-item">
          <img class="swiper-img" src="../assets/images/swiper/2.png" alt="">
        </van-swipe-item>
        <van-swipe-item class="my-swipe-item">
          <img class="swiper-img" src="../assets/images/swiper/3.png" alt="">
        </van-swipe-item>
        <van-swipe-item class="my-swipe-item">
          <img class="swiper-img" src="../assets/images/swiper/4.png" alt="">
        </van-swipe-item>
        <van-swipe-item class="my-swipe-item">
          <img class="swiper-img" src="../assets/images/swiper/5.png" alt="">
        </van-swipe-item>
      </van-swipe>
      </div>
      <div :class="isMobile ? 'footer-btns' : 'pc-width footer-btns'" style="z-index： 99">
        <a href="https://www.facebook.com/NorthTowerGame/" target="_blank">
          <img src="../assets/images/icon/fb.png" alt="">
        </a>
        <a href="mailto:support@northtowergame.com" target="_blank">
          <img src="../assets/images/icon/email.png" alt="">
        </a>
        <!-- <a href="https://twitter.com/NorthTowerGame" target="_blank">
          <img src="../assets/images/icon/ding.png" alt="">
        </a> -->
        <a href="https://discord.gg/jBVF7sRkD5" target="_blank">
          <img src="../assets/images/icon/discord.png" alt="">
        </a>
      </div>
      
    </div>
    
  </div>
</template>
<script>
// import { Col, Row, DropdownMenu,  DropdownItem} from 'vant';
import { Swipe, SwipeItem } from 'vant';
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data () {
    return {
      value1: 0,
      show: false,
      localLang: [
        {text: 'English', value: 0}
      ]
    }
  },
  mounted () {
    console.log(this.isMobile, 'isMobile')
  },
  methods: {
    setShow () {
      this.show = !this.show
    }
  }
}
</script>
<style lang="scss" scoped>
.pc-width {
  max-width: 2rem;

}
  .index-bg {
    background: url('../assets/images/bg/index-bg.png');
    background-position: center;
    background-size: cover;
    padding-top: 40px;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    .logo {
      width: 20%;
      margin: 0 auto;
    }
    .btns {
      width: 50%;
      margin: 0 auto;
      display: flex;
      // background-color: #956B61;
      justify-content: center;
      text-align: center;
      img {
        width: 90%;
        margin: 0 5%;
      }
    }

  }
  .index-bottom-bg {
    background-position: center;
    background-size: cover;
    // background-color: red;
    // padding-top: 40px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    padding: 20px;
    padding-bottom: 90px;
    .mask {
      width: 100%;
      // filter: blur(10px);
      height: 100%;
      position: absolute;
      // padding-bottom: 90px;
      // background-color: rgba($color: #ffffff, $alpha: 0.5);
       background: url('../assets/images/bg/index-bg.png') no-repeat;
      // background-color: green;
      background-size: cover;
      opacity: 0.1;
      top: 0;
      left: 0;
      z-index: 0;
      
    }
    .title-box {
      display: flex;
      width: 100%;
      font-size: 10px;
      margin: 20px auto;
      justify-content:space-between;
      align-items:center;
      // z-index: 1000000;
      z-index: inherit;
      .title-text {
        margin: 0 10px;
        color: #ffffff;
        flex: 1;
        text-stroke: 1px #956B61;
         z-index: 10;
        -webkit-text-stroke: 1px #956B61;
      }
      .left-ls {
        width: 10%;
        margin-top: 5x;
        z-index: 10;

      }
      .right-ls {
        rotate: 180deg;
        width: 10%;
      }
    }
    .my-swipe {
      // width: 70%;
      width: 100%;
      margin: 0 auto;
      border-radius: 10px;
      text-align: center;
      z-index: 100;
      .my-swipe-item {
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
      }
      .swiper-img {
        border-radius: 10px;
        width: 100%;
      }
    }
  }
  .footer-btns {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    z-index: 100;
    z-index: 100;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    a {
      display: inline-block;
      width: 10%;
      z-index: 100;
      margin: 0 10px;
      img {
        width: 100%;
        z-index: 100;
      }
    }
  }
</style>